/*           badges             */
.badge{
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: $font-size-mini;
    line-height: 12px;
    background-color: $transparent-bg;
    border: $border;
    text-decoration: none;
    color: $white-color;
    margin-bottom: 5px;

    &:hover,
    &:focus{
      text-decoration: none;
    }
    & + .badge{
      margin-left: 5px;
    }
}
.badge-icon{
    padding: 0.4em 0.55em;
    i{
       font-size: 0.8em;
    }
}
.badge-default{
    @include badge-color($default-color);
}
.badge-primary{
    @include badge-color($primary-color);
}
.badge-info{
    @include badge-color($info-color);
}
.badge-success{
    @include badge-color($success-color);
}
.badge-warning{
    @include badge-color($warning-color);
}
.badge-danger{
    @include badge-color($danger-color);
}
.badge-neutral{
    @include badge-color($white-color);
    color: inherit;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover{
  @include badge-hover-href($white-color, darken($primary-color, 3%));
}

.badge-warning[href]:focus,
.badge-warning[href]:hover{
  @include badge-hover-href($white-color, darken($warning-color, 3%));
}

.badge-info[href]:focus,
.badge-info[href]:hover{
  @include badge-hover-href($white-color, darken($info-color, 3%));
}

.badge-danger[href]:focus,
.badge-danger[href]:hover{
  @include badge-hover-href($white-color, darken($danger-color, 3%));
}

.badge-success[href]:focus,
.badge-success[href]:hover{
  @include badge-hover-href($white-color, darken($success-color, 3%));
}

.badge-default[href]:focus,
.badge-default[href]:hover{
  @include badge-hover-href($white-color, darken($default-color, 3%));
}
